<!-- 批量设置 -->
<template>
  <div class="batch-setting">
    <div class="table-head">
      <p class="title">已选印章</p>
      <!-- <a-button type="primary" @click="popupInfo.showPopup = true" :disabled="isDisabled">{{
        `设置${btnName[$route.query.type]}`
      }}</a-button> -->
      <!-- TODO：新增批量新增/批量删除 -->
      <div class="button-group">
        <a-button @click="showSelectPop(1)" :disabled="isDisabled" class="batch-button">批量新增</a-button>
        <a-button @click="showSelectPop(2)" :disabled="isDisabled" class="batch-button">批量删除</a-button>
        <!-- 相当于重置 -->
        <a-button @click="showSelectPop(3)" :disabled="isDisabled" class="batch-button">批量设置</a-button>
      </div>
    </div>
    <a-table :columns="columns" :data-source="listData" :pagination="false" rowKey="sealId">
      <!-- 印章名称 -->
      <template #sealName="{ record }">
        <span v-if="record.sealName.length < 21">{{ record.sealName ?? '--' }}</span>
        <a-tooltip placement="bottomLeft" v-else overlayClassName="lang-name-pop">
          <template #title>
            <span>{{ record.sealName }}</span>
          </template>
          <div class="sealName-ellipsis">{{ record.sealName }}</div>
        </a-tooltip>
      </template>
      <!-- 印章保管员/特权用印人/数据查看人 -->
      <template #staffInfoList="{ record }">
        <span v-if="!record.ellipsis">{{ record.personString ?? '--' }}</span>
        <a-tooltip placement="bottomLeft" v-else overlayClassName="seal-trustee-overlay">
          <template #title>
            <span>{{ record.personString }}</span>
          </template>
          <div class="box">
            <div class="people-content-box">
              <div v-for="(item, index) in record.staffInfoList" :key="index" class="person-box">
                <div class="ellipsis">{{ item.staffName }}</div>
                <span v-if="index !== record.staffInfoList.length - 1">、</span>
              </div>
            </div>
            <span v-if="record.personString.length > 50">...</span>
          </div>
        </a-tooltip>
      </template>
      <!-- 操作 -->
      <template #action="{record}">
        <a @click="remove(record)" class="delete">移除</a>
      </template>
    </a-table>
    <!-- 批量设置选择人员弹窗 -->
    <a-modal
      :title="`选择${btnName[$route.query.type]}`"
      class="global-range-modal batch-setting-modal"
      centered
      :visible="popupInfo.showPopup"
      :width="655"
      @cancel="handleCancel"
      @ok="handleOk"
      :confirmLoading="isClick"
    >
      <div class="content-box">
        <PersonAuthorization
          :selectData="popupInfo.selectPersonList"
          @checkData="checkData"
          ref="sonRef"
          :isPopup="true"
          :noPermissionControl="true"
        />
      </div>
    </a-modal>
  </div>
</template>

<script>
import { computed, defineComponent, onMounted, reactive, ref, toRefs, watch } from 'vue'
import PersonAuthorization from '../businessManage/role/components/personAuthorization.vue'
import { useRoute } from 'vue-router'
import { getSettingDetail, batchSetting } from '@/apis/sealManage.js'
import { transformPeople } from '@/apis/businessManage'
import { cmsNotice } from '@/utils/utils'

const btnName = {
  1: '印章保管员',
  3: '特权用印人',
  2: '数据查看人'
}

export default defineComponent({
  components: {
    PersonAuthorization
  },
  props: {},
  setup() {
    // TODO：批量设置相关
    const currentOperation = ref(-1)
    const sonRef = ref(null)
    const isClick = ref(false)
    const checkedIds = ref([])
    const isDisabled = ref(false)
    const sealIds = ref('')
    const sealIdList = ref([])
    const route = useRoute()
    const columns = ref([
      {
        title: '印章名称',
        dataIndex: 'sealName',
        key: 'sealName',
        slots: { customRender: 'sealName' },
        align: 'left',
        width: 312
      },
      {
        title: '印章保管员',
        dataIndex: 'staffInfoList',
        key: 'staffInfoList',
        slots: { customRender: 'staffInfoList' },
        align: 'left',
        ellipsis: true,
        width: 560
      },
      {
        title: '操作',
        dataIndex: 'action',
        key: 'action',
        align: 'left',
        slots: { customRender: 'action' },
        width: 78
      }
    ])
    const popupInfo = reactive({
      selectPersonList: [], //选择的人员
      showPopup: false
    })
    // 显示人员选择器
    const showSelectPop = type => {
      popupInfo.showPopup = true
      currentOperation.value = type
    }
    //表格数据
    const listData = ref([])
    //获取列表
    const getList = () => {
      switch (route.query.type) {
        case '1':
          columns.value[1].title = '印章保管员'
          break
        case '3':
          columns.value[1].title = '特权用印人'
          break
        case '2':
          columns.value[1].title = '数据查看人'
          break
      }
      // TODO：请求接口获取列表数据
      getSettingDetail({ type: route.query.type, sealIds: sealIds.value })
        .then(res => {
          if (res.success) {
            listData.value = res.data

            if (res.data.length === 0) {
              isDisabled.value = true
              localStorage.setItem('checkedRows', JSON.stringify([]))
              return
            }
            console.log('列表数据', listData.value.length)
            //对列表数据-印章保管员字段处理
            //（单个人的名字超过6个字省略，|| 总共的字数超过50字 ）悬停显示所有完整的信息
            listData.value.forEach(item => {
              item.ellipsis = false
              let personList = []
              if (!item.staffInfoList || !item.staffInfoList.length) return
              item.staffInfoList.forEach((ele, ind) => {
                console.log('item.staffInfoList', ele)
                personList.push(ele.staffName)
                if (ele.staffName.length > 6) item.ellipsis = true
              })
              item.personString = personList.join('、')
              if (item.personString.length > 50) item.ellipsis = true
            })
            console.log('formate后的data的值', listData.value)
          }
        })
        .catch(err => {
          console.log(err)
        })
    }
    //删除记录
    const remove = row => {
      console.log('行记录', row)

      let newArr = JSON.parse(localStorage.getItem('checkedRows'))
      newArr = newArr.filter(item => item != row.sealId)
      console.log('newArr的值', newArr)
      sealIdList.value = newArr
      localStorage.setItem('checkedRows', JSON.stringify(newArr))
      sealIds.value =
        JSON.parse(localStorage.getItem('checkedRows')).length !== 0
          ? JSON.parse(localStorage.getItem('checkedRows')).join(',')
          : ''
      console.log('删除后的sealIds', sealIds.value)
      //TODO请求接口执行删除记录操作
      getList()
    }
    //批量操作-选择人员弹窗
    const checkData = e => {
      popupInfo.selectPersonList = e
      console.log('选择了人员', popupInfo.selectPersonList)
    }

    // 处理部门转化人员
    const transformData = async () => {
      if (!popupInfo.selectPersonList.length) return
      checkedIds.value = []
      let deptIds = []
      popupInfo.selectPersonList.map(i => {
        if (i.dataType == 2) {
          deptIds.push(i.dataId)
        } else {
          checkedIds.value.push(i.dataId)
        }
      })
      const res = await transformPeople({ deptIds })
      if (res.success && res.data?.length) {
        res.data.forEach(i => {
          checkedIds.value.push(i.staffId)
        })
        checkedIds.value = [...new Set(checkedIds.value)]
      }
    }
    const handleOk = async () => {
      if (isClick.value) return
      isClick.value = true
      await transformData()

      // updateType 操作类型 1批量新增,2批量删除,3批量设置
      // type 设置类型,1印章管理员,2数据查看人,3特权用印人
      batchSetting({
        sealIds: sealIds.value.split(','),
        staffIds: checkedIds.value,
        type: route.query.type,
        updateType: currentOperation.value
      })
        .then(res => {
          setTimeout(() => {
            isClick.value = false
          }, 500)

          if (res.success && popupInfo.selectPersonList.length !== 0) {
            if (!res.data.reason) cmsNotice('success', '设置成功')
            else cmsNotice('warning', res.data.reason)
          }

          handleCancel()
          getList()
          console.log('popupInfo.selectPersonList', popupInfo.selectPersonList)
        })
        .catch(err => {
          setTimeout(() => {
            isClick.value = false
          }, 500)
          handleCancel()
          getList()
          console.log(err)
        })
    }
    const handleCancel = () => {
      popupInfo.showPopup = false
      popupInfo.selectPersonList = []
      checkedIds.value = []
      sonRef.value.getList()
    }
    onMounted(() => {
      sealIdList.value = JSON.parse(localStorage.getItem('checkedRows'))
      sealIds.value = sealIdList.value.join(',')
      isDisabled.value = sealIdList.value.length === 0
      getList()
    })
    watch(
      () => sealIdList.value.length,
      () => {
        isDisabled.value = sealIdList.value.length === 0
      }
    )
    return {
      remove,
      listData,
      columns,
      getList,
      btnName,
      popupInfo,
      checkData,
      handleOk,
      handleCancel,
      sealIds,
      isDisabled,
      sealIdList,
      checkedIds,
      transformData,
      isClick,
      sonRef,
      currentOperation,
      showSelectPop
    }
  }
})
</script>
<style lang="less" scoped>
.batch-setting {
  background: #fff;
  padding: 16px 24px;
  margin: 0 24px;
  height: calc(100vh - 124px);
  .table-head {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
    .title {
      font-size: 16px;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.85);
    }
    .button-group {
      .batch-button {
        width: 72px;
        height: 32px;
        border-radius: 2px;
        border: 1px solid #c3161c;
        font-weight: 400;
        font-size: 14px;
        color: #c3161c;
        line-height: 20px;
        padding: 0;
        margin-right: 8px;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
  .sealName-ellipsis {
    max-width: 21em;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  .box {
    display: flex;
    flex-direction: row;
    align-items: center;
    .people-content-box {
      overflow: hidden;
      max-width: 560px;

      display: flex;
      flex-direction: row;
      align-items: center;
      .person-box {
        display: flex;
        flex-direction: row;
        align-items: center;

        .ellipsis {
          max-width: 6em;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          display: inline-block;
        }
      }
    }
  }

  .delete {
    font-size: 14px;
    font-weight: 400;
    color: #1890ff;
  }
}
</style>
<style lang="less">
.batch-setting-modal {
  .ant-modal-content {
    .ant-modal-header {
    }
    .ant-modal-body {
      height: auto;
      .content-box {
        border: 1px solid rgba(0, 0, 0, 0.06);
        margin-bottom: 24px;
        .model-row {
          .model-right,
          .model-left {
            padding: 16px 14px;
          }
        }
      }
    }

    .ant-modal-footer {
    }
  }
}
</style>
